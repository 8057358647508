export enum TransactionType {
    All = -200,

    Default = 0,

    Receive = 1,

    Send = 2,

    Buy = 3,

    Sell = 4,

    WithDrawRef = 8,

    DividendWithdrawal = 29,

    WithdrawSection = 32,

    Compensation = 33,
    
    WithdrawRipeIndexDividends = 50,

    DividendsRequest = 54,
}