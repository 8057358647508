import { Routes } from '@angular/router';
export const content: Routes = [
  { path: '', redirectTo: 'overview', pathMatch: 'full' },
  { path: 'overview', loadChildren: () => import('../../components/portfolio/portfolio.module').then(m => m.PortfolioModule) },
  //{ path: 'dashboard', loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'wallet/:subpageRoute', loadChildren: () => import('../../components/wallet/wallet.module').then(m => m.WalletModule) },
  { path: 'wallet', loadChildren: () => import('../../components/wallet/wallet.module').then(m => m.WalletModule) },
  { path: 'indexes/:id', loadChildren: () => import('../../components/invest/index.module').then(m => m.IndexModule)},
  { path: 'all-indexes', loadChildren: () => import('../../components/all-indexes/all-indexes.module').then(m => m.AllIndexesModule) },
  { path: 'faq', loadChildren: () => import('../../components/faq/faq.module').then(m => m.FaqModule) },
  { path: 'documents', loadChildren: () => import('../../components/documentation/documentation.module').then(m => m.DocumentationModule) },
  { path: 'user-profile/:subpage', loadChildren: () => import('../../components/user-profile/user-profile.module').then(m => m.UserProfileModule) },
  { path: 'auth/:subpageRoute', loadChildren: () => import('../../app.module').then(m => m.AppModule) },
  { path: 'track-info/:id', loadChildren: () => import('../../components/track-info/track-info.module').then(m => m.TrackInfoModule) },
  { path: 'verification', loadChildren: () => import('../../components/account/verification/verification.module').then(m => m.VerificationModule) },
  { path: 'referral/:subpage', loadChildren: () => import('../../components/account/referral/referral.module').then(m => m.ReferralModule) },
  { path: 'notifications', loadChildren: () => import('../../components/notifications/notifications.module').then(m => m.NotificationsModule) },
  { path: 'deposit', loadChildren: () => import('../../components/deposit/deposit.module').then(m => m.DepositModule) },
];

export const adminContent: Routes = [
  { path: '', redirectTo: 'admin/users/users-list', pathMatch: 'full' },
  { path: 'auth/:subpageRoute', loadChildren: () => import('../../app.module').then(m => m.AppModule) },
  { path: 'admin/analytics/:subpage', loadChildren: () => import('../../components/admin/analytics/admin-analytics.module').then(m => m.AdminAnalyticsModule) },
  { path: 'admin/users/:subpage', loadChildren: () => import('../../components/admin/users/admin-users.module').then(m => m.AdminUsersModule) },
  { path: 'admin/user-profile/:id', loadChildren: () => import('../../components/admin/user-profile/admin-user-profile.module').then(m => m.AdminUserProfileModule) },
  { path: 'admin/documentation', loadChildren: () => import('../../components/admin/documentation/documentation.module').then(m => m.DocumentationModule) },
  { path: 'admin/dashboard', loadChildren: () => import('../../components/admin/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'admin/settings/:subpage', loadChildren: () => import('../../components/admin/settings/settings.module').then(m => m.SettingsModule) },
  { path: 'admin/payments', loadChildren: () => import('../../components/admin/payments/payments.module').then(m => m.PaymentsModule) },
  { path: 'admin/royalty', loadChildren: () => import('../../components/admin/royalty/royalty.module').then(m => m.RoyaltyModule) },
  { path: 'admin/events', loadChildren: () => import('../../components/admin/events/events.module').then(m => m.EventsModule) }
];