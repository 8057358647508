import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { permissions } from '../../constants';
import { AuthService, CryptoUserService, DocumentService, EventService, SubscriptionService, UserService } from '../../services';
import { NavService, Page } from '../../services/nav.service';
import { CryptoUserModel, HeaderNotificationModel, NotificationModel } from '../../model';
import { BaseComponent } from '../base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { documentType } from '../../constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  public menuItems: Page[];
  //public isActiveBar: boolean = false;
  public cryptoUser: () => CryptoUserModel;
  public currentYear: number;
  public externalUrls = environment.externalUrls;
  public headerNotification: HeaderNotificationModel = new HeaderNotificationModel;
  docNames = new Map();
  existingTypes : number[] = [];
  documentType = documentType;
  subscription: Subscription;

  constructor(
    private router: Router,
    public navService: NavService,
    private userService: UserService,
    private cryptoUserService: CryptoUserService,
    private subscriptionService: SubscriptionService,
    public authService: AuthService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private documentService: DocumentService,
    private eventService: EventService
  ) {
    super(translate, modalService);


    this.currentYear = new Date().getFullYear();

    var items = environment.buildAdmin ? this.navService.adminItems : this.navService.mainItems;
    items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          t.updateActiveTabs(event.url);
          if(event.url != t.navService.currentUrl){
            t.navService.previousUrl = t.navService.currentUrl;
            t.navService.currentUrl = event.url;
          }
        }
      })
    });

    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationStart) {
        var sidebar = document.getElementById('sidebar');
        if(sidebar) sessionStorage.setItem('sidebarScrollY', sidebar.scrollTop.toString());
      }
    });

    var t = this;
    if (window.innerWidth < 569)
      t.navService.collapseSidebar = false;

    t.cryptoUser = () => {
      if (!!t.cryptoUserService.get())
        return t.cryptoUserService.get();
      else return new CryptoUserModel();
    }
  }

  ngOnInit(): void {
    let t = this;

    t.getNotifications('en');
    t.docsExistenceCheck();
  }

  openChat(): void {
    if ((window as any).tidioChatApi) {
        (window as any).tidioChatApi.show();
        (window as any).tidioChatApi.open();
    }
  }

  ngAfterViewInit() {
    let t = this;
    t.updateActiveTabs(t.router.url);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  toDashboard(){
    this.router.navigate(['/dashboard']);
  }

  updateActiveTabs(url: string){
    var t = this;
    //ищем выбранный элемент среди menuItems
    var curItem = t.menuItems.find(x => x.path == url && x.type == 'link');
    if(!curItem){
      //если выбранного элемента нет среди menuItems - смотрим детей
      t.menuItems.forEach(items => {
        if(!!items?.children && !curItem) {
          curItem = items.children.find(x => x.path === url);
        }
      })
    }
    //возврат, если выбранного эл-та нет среди menuItems и детей
    if(!curItem)
      return;

    curItem.active = true;
    //проходимся по всем эл-там, которые не являются выбранным
    t.menuItems.filter(x => x.path != curItem.path).forEach(menuItem => {
      //делаем эл-т активным, если у среди его детей есть выбранный
      menuItem.active = !!menuItem.children?.find(x => x.path === url);
      if(!!menuItem.children){
        //проходимся по всем дочерним не выбранным эл-там и проставляем им false
        menuItem.children.filter(x => x.path != curItem.path).forEach(child => {
          child.active = false;
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    var t = this;
    if(item.path == t.router.url){
      return;
    }

    t.menuItems.forEach(a => {
      if (t.menuItems.includes(item))
        a.active = false
      if (!a.children) return false
      a.children.forEach(b => {
        if (a.children.includes(item)) {
          b.active = false
        }
      })
    });
    //Закрытие навбара при переходе на другие страницы в мобилке
    if (window.innerWidth < 569 && t.navService.collapseSidebar)
      t.collapseSidebar();
    item.active = !item.active;
  }

  needHidden(navRoles: string[], title: string){
    var t = this;

    if (!t.userService.get()) {
      return true;
    }

    var isHide = false;
    var userRoles = t.userService.getRole();

    // если админ верифицирован, то для него раздел верификации скрывается тоже
    if (userRoles.includes(permissions.Master) && !userRoles.includes(permissions.NotVerified) && title === 'Verification')
      return true;

    // раздел Payments доступен только для AllUsersMaster (костыль для того, чтобы видели только разрабы)
    if (title === 'Payments' && !userRoles.includes(permissions.AllUsersMaster)) {
      return true;
    }

    //админ по умолчанию имеет доступ ко всем разделам
    if (userRoles.includes(permissions.Master)) {
      return isHide;
    }

    if (!!navRoles) {
      navRoles.forEach(navRole => {
        if (!userRoles.includes(navRole)) {
          isHide = true;
        }
      })
    }

    return isHide;
  }

  showNotifications(){
    this.navService.showNotif = !this.navService.showNotif;
  }

  collapseSidebar() {
    this.navService.collapseSidebar = !this.navService.collapseSidebar;
    this.navService.toggleSidebarEvent.emit();
  }

  getAvatar(): string{
    var t = this;
    if(t.cryptoUser().avatarUrl)
      return t.cryptoUser().avatarUrl;
    else return t.defaultAvatarUrl;
  }

  getUserName(){
    let t = this;
    if(t.cryptoUser().firstname && t.cryptoUser().lastname)
    {
      return `${t.cryptoUser().firstname} ${t.cryptoUser().lastname}`;
    }
    else return 'No Name';
  }

  getNotifications(lang) {
    var t = this;
    return t.subscriptionService.getLocalizedNotifications(lang)
      .then(resp => {
        t.headerNotification = resp.data;
      })
      .catch(er => {
        // t.showResponseError(er);
      })
  }

  checkNotification(notif: NotificationModel) {
    if (notif.isChecked) return;
    notif.isChecked = true;
    var t = this;
    t.subscriptionService.checkNotification(notif.id)
      .then(resp => {
        if (resp.data) {
          t.headerNotification.notCheckedCount--;
        }
      })
  }

  checkAllNotifications() {
    var t = this;
    t.setLoading(true);
    t.subscriptionService.checkAllNotifications()
      .then(resp => {
        if (resp.data) {
          t.headerNotification.notCheckedCount = 0;
          t.headerNotification.notifications.map(x => x.isChecked = true);
          t.showSuccess("All notifications checked");
        }
      })
      .catch(er => t.showResponseError(er))
      .finally(() => t.setLoading(false));
  }

  toSettings(){
    var t = this;
    t.collapseSidebar();
    t.router.navigate(['user-profile/security']);
  }

  toProfile() {
    var t = this;
    t.collapseSidebar();
    t.router.navigateByUrl('/user-profile/info');
  }

  toNotifications(){
    var t = this;
    t.collapseSidebar();
    t.router.navigateByUrl('/notifications');
  }

  docsExistenceCheck() {
    let t = this;

    return t.documentService.docsExistenceCheck()
      .then(response => {
        t.existingTypes = response.data;
        t.existingTypes.forEach(type => {
          let label = t.documentType.find(x => x.value === type).label;
          t.docNames.set(type, label);
        })
      })
      .catch(ex => {
        t.showResponseError(ex);
      })
  }
}
